import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/resource/sidebar-logo.png";
import logonew from "../../assets/images/logo.png";

function Main() {
  const [active, setActive] = useState();
  const [lastscrool, setlastscrool] = useState(0);

  const [scrolled, setScrolled] = useState(false);
  const [Nav, setNav] = useState(true);

  const handleScroll = () => {
    const offset = window.scrollY;
    setlastscrool(offset);

    if (offset > 1000 && offset < lastscrool) {
      setNav(true);
      setScrolled(true);
    } else if (offset > 1000) {
      setNav(false);
    } else if (offset > 200) {
      setNav(true);
      setScrolled(true);
    } else {
      setScrolled(false);
      setNav(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <>
      {active && <div className="menu-backdrop" style={{ opacity: "1", visibility: "visible" }}></div>}
      <section className={`hidden-bar ${active && "visible-sidebar"}`}>
        <div className="inner-box">
          <div className="cross-icon hidden-bar-closer" onClick={() => setActive(false)}>
            <span className="far fa-close" onClick={() => setActive(false)}>
              {" "}
            </span>
          </div>
          <div className="logo-box">
            <Link to="/" title="Fenice">
              <img src={logo} alt="Fenice" title="Fenice, Italian Design" />
            </Link>
          </div>

          <div className="side-menu">
            <ul className="navigation clearfix">
              <li>
                <Link to="/" onClick={() => setActive(false)}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/ourproducts" onClick={() => setActive(false)}>
                  Productos
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={() => setActive(false)}>
                  Quienes Somos
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={() => setActive(false)}>
                  Contáctanos
                </Link>
              </li>
              <li>
                <Link to="https://english.fenicelatam.com/" onClick={() => setActive(false)}>
                  English
                </Link>
              </li>
            </ul>
          </div>
          <ul className="info">
            <li>
              Caracas <br /> Venezuela{" "}
            </li>
            <li>Lun - Vie : 08.30 am - 04.30 pm</li>
            <li>
              <Link to="mailto:booking@domainame.com">fenice@fenicelatam.com</Link>
            </li>
          </ul>
          <div className="separator">
            <span></span>
          </div>
          <div className="booking-info">
            <div className="bk-title">Teléfono</div>
            <div className="bk-no">
              <Link to="https://api.whatsapp.com/send?phone=584125864680">WhatsApp</Link>
            </div>
          </div>
        </div>
      </section>
      {Nav && (
        <header className={`main-header ${scrolled && "fixed-header"} header-down`}>
          <div className="header-top">
            <div className="auto-container">
              <div className="inner clearfix">
                <div className="top-left clearfix">
                  <ul className="top-info clearfix">
                    <li>
                      <i className="icon far fa-map-marker-alt"></i> Caracas - Venezuela
                    </li>
                    <li>
                      <i className="icon far fa-clock"></i> Lun - Vie : 08.30 am - 04.30 pm
                    </li>
                  </ul>
                </div>
                <div className="top-right clearfix">
                  <ul className="top-info clearfix">
                    <li>
                      <Link to="https://api.whatsapp.com/send?phone=584125864680">
                        <i className="icon far fa-phone"></i>+58-4125864680
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="mailto:fenice@fenicelatam.com">
                        <i className="icon far fa-envelope"></i> fenice@fenicelatam.com
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="header-upper header-fixed">
            <div className="auto-container">
              <div className="main-box d-flex justify-content-between align-items-center flex-wrap">
                <div className="links-box clearfix">
                  <div className="nav-toggler">
                    <button className="hidden-bar-opener">
                      <span className="hamburger" onClick={() => setActive(true)}>
                        <span className="top-bun"></span>
                        <span className="meat"></span>
                        <span className="bottom-bun"></span>
                      </span>
                    </button>
                  </div>
                </div>

                <div className="logo-box">
                  <div className="logo">
                    <Link to="/" title="Fenice Italian Design">
                      <img src={logonew} alt="" title="Fenice" />
                    </Link>
                  </div>
                </div>

                <div className="link link-btn">
                  <Link to="https://api.whatsapp.com/send?phone=584125864680" className="theme-btn btn-style-two clearfix">
                    <span className="btn-wrap">
                      <span className="text-one">Contáctanos</span>
                      <span className="text-two">Contáctanos</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
}

export default Main;
