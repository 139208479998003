import React from "react";
import { Link } from "react-router-dom";
import One from "../../../assets/images/products/menu-image-11.webp";
import Two from "../../../assets/images/products/menu-image-12.webp";
import Seven from "../../../assets/images/products/menu-image-17.webp";
import Four from "../../../assets/images/products/menu-image-14.webp";
import Five from "../../../assets/images/products/menu-image-15.webp";
import Six from "../../../assets/images/products/menu-image-16.webp";
import Eight from "../../../assets/images/products/menu-image-18.webp";
import catalog from "../../../assets/catalog/FeniceCatalog.pdf";

function Specialmenu() {
  return (
    <>
      <section className="special-offer-two">
        <div className="auto-container">
          <div className="title-box centered">
            <br></br>
            <br></br>
            <h2>Vasos</h2>
          </div>
          <div className="row clearfix">
            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="image">
                  <img src={Eight} alt="" />
                </div>
                <h4>
                  <Link to="/menu">250 ml</Link>
                </h4>
                <div className="text desc">Pequeño, compacto y practico para cada aplicación.</div>
              </div>
            </div>

            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="image">
                  <img src={One} alt="" />
                </div>
                <h4>
                  <Link to="/menu">330 ml</Link>
                </h4>
                <div className="text desc">Mediano, el tamaño perfecto para cócteles, jugos, cafés helados y mucho más.</div>
              </div>
            </div>

            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                <div className="image">
                  <img src={Two} alt="" />
                </div>
                <h4>
                  <Link to="/menu">500 ml</Link>
                </h4>
                <div className="text desc">Tamaño grande, nuestros vasos sirven para cualquier tipo de bebida. Destaca tu marca.</div>
              </div>
            </div>

            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                <div className="image">
                  <img src={Seven} alt="" />
                </div>
                <h4>
                  <Link to="/menu">Tapas y Pestañas</Link>
                </h4>
                <div className="text desc">
                  Personalizaciones que se adaptan a tus gustos y necesidades. Diferenciate y destaca tus productos con Fenice.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lower-link-box text-center">
          <a href={catalog} download="FENICECATALOG" className="theme-btn btn-style-two clearfix">
            <span className="btn-wrap">
              <span className="text-one">Descarga nuestro Catálogo 2024</span>
              <span className="text-two">Descarga nuestro Catálogo 2024</span>
            </span>
          </a>
        </div>
      </section>

      <section className="special-offer-two">
        <div className="auto-container">
          <div className="title-box centered">
            <h2>Nuestras Maquinas</h2>
          </div>
          <div className="row clearfix">
            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                <div className="image">
                  <img src={Four} alt="" />
                </div>
                <h4>
                  <Link to="/menu">Automática</Link>
                </h4>
                <div className="text desc">Ideal para cualquier requerimiento mediano o grande.</div>
              </div>
            </div>

            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                <div className="image">
                  <img src={Five} alt="" />
                </div>
                <h4>
                  <Link to="/menu">Full-Automática</Link>
                </h4>
                <div className="text desc">Nuestra tope de gama. Llevado a otro nivel.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="lower-link-box text-center">
          <a href={catalog} download="FENICECATALOG" className="theme-btn btn-style-two clearfix">
            <span className="btn-wrap">
              <span className="text-one">Descarga nuestro Catálogo 2024</span>
              <span className="text-two">Descarga nuestro Catálogo 2024</span>
            </span>
          </a>
        </div>
      </section>

      <section className="chef-selection">
        <div className="auto-container">
          <div className="outer-container">
            <div className="row clearfix">
              <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                  <div className="image-layer" style={{ backgroundImage: `url(${Six})` }}></div>
                </div>
              </div>

              <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                  <div className="title-box">
                    <div className="subtitle">
                      <span>Elevate con Nosotros</span>
                    </div>

                    <div className="pattern-image">
                      <img src={require("../../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
                    </div>

                    <h3>Somos Fenice!</h3>
                    <div className="text">
                      Dejaste llevar por una experiencia unica, nuestros vasos sellados por nuestras maquinas destancan de manera sin igual y son un
                      atractivo unico e innovador en cualquier evento o locación.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Specialmenu;
