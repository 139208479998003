import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "../components/Header/Main";
import Footer from "../components/Footer/Main";
import Home from "../components/Home/Main";
import About from "../components/About/Main";
import Products from "../components/Products/Main";
import Contact from "../components/Contact/Main";

function Index() {
  const [homepage, sethomepage] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/hometwo") {
      sethomepage(false);
    } else {
      sethomepage(true);
    }
  }, [location]);

  const path = location.pathname;
  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <>
      {homepage && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/ourproducts" element={<Products />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default Index;
