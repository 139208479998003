import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper.min.css";
import menuimgone from "../../assets/images/resource/menu-image-11.webp";
import menuimgthree from "../../assets/images/resource/menu-image-13.webp";
import menuimgfour from "../../assets/images/resource/menu-image-14.webp";
import menuimgseven from "../../assets/images/resource/menu-image-17.webp";
import menuimgtwo from "../../assets/images/resource/menu-image-12.webp";
import catalog from "../../assets/catalog/FeniceCatalog.pdf";

SwiperCore.use([Navigation]);

function Special() {
  const options = {
    slidesPerView: 4,
    spaceBetween: 45,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      delay: 5000,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      600: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };

  return (
    <>
      <section className="special-offer">
        <div className="outer-container">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="pattern-image">
                <img src={require("../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
              </div>
              <h2>Nuestros Productos</h2>
            </div>
            <Swiper
              className="dish-gallery-slider"
              {...options}
              loop={true}
              spaceBetween={45}
              slidesPerView={4}
              navigation={{ nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" }}
            >
              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={menuimgtwo} alt="cans" />
                </div>
                <h4>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to="/ourproducts">Vasos de Plastico | 250 ml</Link>
                  </div>
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="text desc">Diseño moderno y compacto. </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={menuimgone} alt="cans" />
                </div>
                <h4>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to="/ourproducts">Vasos de Plastico | 330 ml y 500 ml</Link>
                  </div>
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="text desc">Diseño moderno y práctico. </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={menuimgseven} alt="cans" />
                </div>
                <h4>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to="/ourproducts">Tapas y Pestañas</Link>
                  </div>
                </h4>
                <br></br>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="text desc">Destaca tu productos con Fenice. </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={menuimgthree} alt="auto sealing machine" />
                </div>
                <h4>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to="/ourproducts">Máquina de Sellado Automática</Link>
                  </div>
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="text desc">Solo 5 segundos.</div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={menuimgfour} alt="full auto sealing machine" />
                </div>
                <h4>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to="/ourproducts">Máquina de Sellado Automática.</Link>
                  </div>
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="text desc">Solo 2 segundos.</div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="swiper-button-prev">
              <span className="fal fa-angle-left"></span>
            </div>
            <div className="swiper-button-next">
              <span className="fal fa-angle-right"></span>
            </div>
            <div className="lower-link-box text-center">
              <a href={catalog} download="FENICECATALOG" className="theme-btn btn-style-two clearfix">
                <span className="btn-wrap">
                  <span className="text-one">Descarga nuestro Catálogo</span>
                  <span className="text-two">Descarga nuestro Catálogo</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Special;
