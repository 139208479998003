import React from "react";
import whyiconone from "../../assets/images/resource/why-icon-1.png";
import whyicontwo from "../../assets/images/resource/why-icon-2.png";
import whyiconthree from "../../assets/images/resource/why-icon-3.png";
import whyiconfour from "../../assets/images/resource/why-icon-4.png";

function Whyus() {
  const whyData = [
    {
      icon: whyiconone,
      title: "Calidad",
      text: "Brindamos una calidad inigualable.",
    },
    {
      icon: whyicontwo,
      title: "Diseño",
      text: "Diseño italiano innovador.",
    },
    {
      icon: whyiconthree,
      title: "Pioneros",
      text: "Somos los primeros y únicos en la región.",
    },
    {
      icon: whyiconfour,
      title: "Versatilidad",
      text: "Tenemos lo que necesitas para destacar.",
    },
  ];

  return (
    <>
      <section className="why-us">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>FENICE</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
            </div>

            <h2>¿QUÉ NOS HACE ÚNICOS?</h2>
          </div>
          <div className="row clearfix">
            {whyData.map((data, index) => (
              <div key={index} className="why-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay={`${index * 300}ms`}>
                  <div className="icon-box">
                    <img src={data.icon} alt="icon" />
                  </div>
                  <h4>{data.title}</h4>
                  <div className="text">{data.text}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Whyus;
