import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Slideone from "../../assets/images/main-slider/slider-1.webp";
import bookicon1 from "../../assets/images/resource/book-icon-1.png";
import "swiper/swiper.min.css";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper.min.css";
import catalog from "../../assets/catalog/FeniceCatalog.pdf";

SwiperCore.use([Navigation]);

function Banner() {
  return (
    <Swiper
      className="banner-section banner-section-coustm"
      loop={true}
      spaceBetween={50}
      slidesPerView={1}
      navigation={{ nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" }} // Use "navigation" with lowercase, and place it outside the SwiperSlide elements
    >
      <div className="banner-container">
        <div className="banner-slider">
          <div className="swiper-wrapper">
            {[Slideone].map((image, index) => (
              <SwiperSlide key={index} className="swiper-slide slide-item img-fluid" style={{ backgroundImage: `url(${image})` }}>
                <div className="auto-container">
                  <div className="content-box">
                    <div className="content">
                      <div className="clearfix">
                        <div className="inner">
                          <div className="subtitle">
                            <span>Diseño Italiano</span>
                          </div>
                          <h1>
                            <span>
                              {index === 0 ? "EVERYTHING’S POSSIBLE" : index === 1 ? "EVERYTHING’S POSSIBLE" : "EVERYTHING’S POSSIBLE"}
                              <br />
                              {index === 0 ? "" : index === 1 ? "" : ""}
                            </span>
                          </h1>
                          <div className="text">
                            <br></br>Equipos de sellado para Vasos de Plástico
                          </div>
                          <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div className="link">
                              <a href={catalog} download="FENICECATALOG" className="theme-btn btn-style-two clearfix">
                                <span className="btn-wrap">
                                  <span className="text-one">Descarga nuestro Catálogo 2024</span>
                                  <span className="text-two">Descarga nuestro Catálogo 2024</span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </div>
      </div>
      <div className="book-btn">
        <Link to="https://api.whatsapp.com/send?phone=584125864680" className="theme-btn">
          <span className="icon">
            <img src={bookicon1} alt="Icono" title="" />
          </span>
          <span className="txt">Whatsapp</span>
        </Link>
      </div>
    </Swiper>
  );
}

export default Banner;
