import React from "react";
import bg26 from "../../assets/images/background/bg-26.png";
import mainchef from "../../assets/images/resource/mainchef.webp";

function Chefsection() {
  return (
    <>
      <section className="chef-section">
        <div className="right-bg">
          <img src={bg26} alt="foto" title="" />
        </div>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="image">
                  <img src={mainchef} alt="foto2" />
                </div>
              </div>
            </div>

            <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="inner clearfix wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="content-box">
                  <div className="title-box">
                    <div className="subtitle">
                      <span>Somos Únicos en el Mercado</span>
                    </div>
                    <div className="pattern-image">
                      <img src={require("../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
                    </div>
                    <h2>Calidad Garantizada</h2>
                    <div className="text">
                      Si estás buscando una calidad excepcional y un diseño de vanguardia en el sellado de bebidas, entonces somos la empresa para ti.
                      Contáctenos hoy para obtener más información sobre nuestros productos y servicios, estamos listos para ayudarte.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Chefsection;
