import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./routes";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-32EM86ZX2C";
ReactGA.initialize(TRACKING_ID);

export default function App() {
  return (
    <>
      <Router>
        <Routing />
      </Router>
    </>
  );
}
