import React from "react";
import Chefsection from "../Menchefs/Chefsection";
import Startermenu from "../Allmenus/FirstMenu/Startermenu";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/background/banner-image-5.webp";

function Main() {
  return (
    <>
      <Bredcrumb subtitle="Conoce Nuestros Productos" title="Vasos + Maquinas Selladoras" Img={Img} />
      <Startermenu />
      <Chefsection />
    </>
  );
}

export default Main;
