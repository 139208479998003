import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/swiper.min.css";
import img1 from "../../assets/images/logos-clientes/1.png";
import img2 from "../../assets/images/logos-clientes/2.png";
import img3 from "../../assets/images/logos-clientes/3.png";
import img4 from "../../assets/images/logos-clientes/4.png";
import img5 from "../../assets/images/logos-clientes/5.png";
import img6 from "../../assets/images/logos-clientes/6.png";
import img7 from "../../assets/images/logos-clientes/7.png";
import img8 from "../../assets/images/logos-clientes/8.png";
import img9 from "../../assets/images/logos-clientes/9.png";
import img10 from "../../assets/images/logos-clientes/10.png";
import img11 from "../../assets/images/logos-clientes/11.png";
import img12 from "../../assets/images/logos-clientes/12.png";
import img13 from "../../assets/images/logos-clientes/13.png";
import img14 from "../../assets/images/logos-clientes/14.png";
import img15 from "../../assets/images/logos-clientes/15.png";
import img16 from "../../assets/images/logos-clientes/16.png";
import img17 from "../../assets/images/logos-clientes/17.png";
import img18 from "../../assets/images/logos-clientes/18.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

function Special() {
  const options = {
    slidesPerView: 4,
    spaceBetween: 45,
    loop: true,
    autoplay: {
      delay: 500,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      600: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };

  return (
    <>
      <section className="slider-clients">
        <div className="outer-container1">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>...</span>
              </div>
              <div className="pattern-image"></div>
              <h5>Fenice es una marca de alta calidad que se encuentra en los mejores hoteles, restaurantes y cafés de todo el país.</h5>
            </div>

            <Swiper
              className="dish-gallery-slider"
              {...options}
              slidesPerView={2}
              autoplay={{
                delay: 500,
                disableOnInteraction: false,
              }}
              speed={5000}
              loop={true}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
            >
              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img1} alt="aCliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/arabianfood_val">Arabian Food</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img2} alt="aCliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/acai.212">AÇAI 212</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img3} alt="aCliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/poke.212">Poke 212</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img4} alt="aCliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/lasalumeriaccs">La Salumeria</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img5} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/greenhauskrause">Greenhause</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img6} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/azupasteleria">Azu Pasteleria</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img7} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/lidotel">Lidotel</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img8} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/croiicafe">Croii Café</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img9} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/migascafe_ve">Miga's Café</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img10} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/golfiao">Golfiao</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img11} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/pastedanubio">Pas. Danubio</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img12} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/quepapaya.ve">Que Papaya</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img13} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/cafemotatan">Café Motatán</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img14} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/origenes.venezuela">Origines</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img15} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/lapanaderia_express/">La Pan. Express</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img16} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/cinecittacaracas">Cine Citta</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img17} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/zigzag.bqto">ZigZag Café</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="image">
                  <img src={img18} alt="Cliente" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h3>
                        <Link to="https://www.instagram.com/vidaloestodo">VIDA Automercado</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default Special;
