import React from "react";
import Contactbanner from "../Mencontactus/Contactbanner";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/background/banner-image-4.jpg";

function Main() {
  return (
    <>
      <Bredcrumb title="¿Alguna duda?" subtitle="Contáctanos" Img={Img} />
      <Contactbanner />
    </>
  );
}

export default Main;
