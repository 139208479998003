import React from "react";
import { Link } from "react-router-dom";
import restro from "../../assets/images/resource/restaurant.webp";

function Contactbanner() {
  return (
    <>
      <section className="contact-page">
        <div className="location-center">
          <div className="auto-container">
            <div className="cinfo-box">
              <div className="row clearfix">
                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                    <h4>Contáctanos</h4>
                    <div className="text">
                      Lunes a Viernes
                      <br /> 08.30 am - 04.30pm
                    </div>
                  </div>
                </div>

                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box cp-seprator wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                    <h4>Contacto</h4>
                    <div className="text">
                      Caracas - Venezuela
                      <br />
                      Email: <br /> fenice@fenicelatam.com
                    </div>
                  </div>
                </div>
                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                    <h4>Teléfono</h4>
                    <div className="more-link">
                      <Link to="https://api.whatsapp.com/send?phone=584125864680">WhatsApp</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="auto-container">
          <div className="c-page-form-box">
            <div className="row clearfix">
              <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                <div className="title-box centered">
                  <h2>Escríbenos</h2>
                  <div className="text desc">¿Tiene alguna pregunta sobre nuestros productos?</div>
                </div>
                <div className="default-form reservation-form">
                  <form method="post" action="https://formsubmit.co/fenice@fenicelatam.com">
                    <div className="clearfix">
                      <div className="form-group">
                        <div className="field-inner">
                          <input type="text" name="name" placeholder="Nombre" required="" />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="field-inner">
                          <input type="email" name="email" placeholder="Email" required="" />
                        </div>
                      </div>
                      <div className="form-group ">
                        <div className="field-inner">
                          <input type="text" name="Phone Number" placeholder="Teléfono" required="" />
                        </div>
                      </div>
                      <div className="form-group ">
                        <div className="field-inner">
                          <textarea name="fieldname" placeholder="Mensaje" required=""></textarea>
                        </div>
                      </div>

                      <div className="form-group">
                        <button type="submit" className="theme-btn btn-style-one clearfix">
                          <span className="btn-wrap">
                            <span className="text-one">Enviar</span>
                            <span className="text-two">Enviar</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                <img src={restro} alt="foto" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactbanner;
