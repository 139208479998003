import React from "react";
import twoBg from "../../assets/images/background/a1.webp";
import threebg from "../../assets/images/background/a2.webp";
import { Link } from "react-router-dom";

function Winningchef() {
  return (
    <>
      <section className="fluid-section alternate">
        <div className="outer-container">
          <div className="row clearfix">
            <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="image-layer" style={{ backgroundImage: `url(${threebg})` }}></div>
                <div className="image">
                  <img src={threebg} alt="" />
                </div>
              </div>
            </div>

            <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="inner clearfix wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="content-box">
                  <div className="title-box centered">
                    <div className="subtitle">
                      <span>Nuestra Historia</span>
                    </div>

                    <div className="pattern-image">
                      <img src={require("../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
                    </div>

                    <h2>Quienes Somos</h2>
                    <div className="text">
                      Somos una empresa comprometida en brindar soluciones prácticas y funcionales para nuestros clientes. Estamos enfocados en
                      ofrecer productos innovadores que se adapten a las necesidades del mercado. Ofrecemos un consumo de bebidas versátil a través de
                      vasos de plástico de alta calidad y equipos de sellado.
                    </div>
                  </div>
                  <Link to="/about" className="theme-btn btn-style-two clearfix">
                    <span className="btn-wrap">
                      <span className="text-one">Leer Más</span>
                      <span className="text-two">Leer Más</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fluid-section">
        <div className="outer-container">
          <div className="row clearfix">
            <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="image-layer" style={{ backgroundImage: `url(${twoBg})` }}></div>
                <div className="image">
                  <img src={twoBg} alt="" />
                </div>
              </div>
            </div>

            <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="inner clearfix wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="content-box">
                  <div className="title-box centered">
                    <div className="subtitle">
                      <span>¡Somos Innovación!</span>
                    </div>

                    <div className="pattern-image">
                      <img src={require("../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
                    </div>

                    <h2>Diseño y Utilidad</h2>
                    <div className="text">
                      En Fenice estamos comprometidos en brindar a nuestros clientes una experiencia óptima y de calidad. Ofrecemos atención al
                      cliente personalizada, una amplia variedad de productos para elegir y un equipo de expertos apasionados por ayudar a los
                      clientes. Sorpréndase con nuestro diseño innovador, que ha sido elogiado por nuestros clientes. ¡Contáctenos hoy para conocer
                      más!
                    </div>
                  </div>
                  <Link to="/ourproducts" className="theme-btn btn-style-two clearfix">
                    <span className="btn-wrap">
                      <span className="text-one">Nuestros Productos</span>
                      <span className="text-two">Nuestros Productos</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Winningchef;
