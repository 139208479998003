import React from "react";
import Aboutsec from "../Menabout/Aboutsec";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/background/banner-image-1.webp";
import Teem from "../Menhome/Teem";
import Feature from "../Menhome/Feature";

function Main() {
  return (
    <>
      <Bredcrumb subtitle="Quienes Somos" title="Conócenos" Img={Img} />
      <Aboutsec />
      <Feature />
      <Teem />
    </>
  );
}
export default Main;
